.header{
    display: flex;
    background-color: black;
    color: white;
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    box-shadow: 0 3px 6px 0 #555;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width:768px){
    .header{
        width: fit-content;
    }
}
.appname{
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
}
.searchbox{
    display: flex;
    padding: 10px 10px;
    background-color: white;
    width: 50%;
    border-radius: 6px;
    align-items: center;
    margin-right: 0.5rem;
}
.searchbox>img{
    width: 2rem;
    height: 2rem;
}
.searchbox>input{
    color: black;
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    width: 8rem;
}
.movielist-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-evenly;
    gap: 5px;
}